<template>
  <v-snackbar
    v-model="snackAppear"
    :timeout="snackbar.timeout || -1"
    :color="snackbar.color || 'primary'"
    elevation="22"
    tile
  >
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="indigo"
        text
        icon
        v-bind="attrs"
        @click="snackAppear = false"
      >
        <v-icon> fas fa-times</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "toast",
  computed: {
    snackAppear: {
      set(val) {
        this.$store.commit("snackAppear", val);
      },
      get() {
        return this.snackbar.appear;
      }
    },
    ...mapGetters({ snackbar: "snackbar" })
  }
};
</script>

<style scoped></style>
